// Generated by Framer (2f783ed)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { useRef } from "react";

const serializationHash = "framer-FoTjI"

const variantClassNames = {c6ARlw44u: "framer-v-ws0fe"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const getProps = ({cursorName, height, id, width, ...props}) => { return {...props, xjlQGu0Qd: cursorName ?? props.xjlQGu0Qd ?? "view"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;cursorName?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const fallbackRef = useRef<HTMLElement>(null)

const refBinding = ref ?? fallbackRef

const defaultLayoutId = React.useId()

const { activeLocale, setLocale } = useLocaleInfo()

const componentViewport = useComponentViewport()

const {style, className, layoutId, variant, xjlQGu0Qd, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "c6ARlw44u", ref: refBinding, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const sharedStyleClassNames = []

const scopingClassNames = cx(serializationHash, ...sharedStyleClassNames)

return (<LayoutGroup id={layoutId ?? defaultLayoutId}><Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(scopingClassNames, "framer-ws0fe", className, classNames)} data-framer-name={"View"} layoutDependency={layoutDependency} layoutId={"c6ARlw44u"} ref={refBinding} style={{backgroundColor: "var(--token-10f2fb8a-d082-42f4-bd26-c9c49d6724c4, rgb(250, 198, 31))", borderBottomLeftRadius: 150, borderBottomRightRadius: 150, borderTopLeftRadius: 150, borderTopRightRadius: 150, ...style}}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "Q1VTVE9NO0FCQyBHaW50byBOb3JtYWwgUmVndWxhcg==", "--framer-font-family": "\"ABC Ginto Normal Regular\", \"ABC Ginto Normal Regular Placeholder\", sans-serif", "--framer-font-size": "12px", "--framer-letter-spacing": "0.32em", "--framer-line-height": "24px", "--framer-text-alignment": "left", "--framer-text-color": "var(--extracted-r6o4lv, var(--token-1b444440-6e98-401c-93cc-651f6c08f890, rgb(20, 20, 31)))", "--framer-text-transform": "uppercase"}}>view</motion.p></React.Fragment>} className={"framer-1ljtkim"} fonts={["CUSTOM;ABC Ginto Normal Regular"]} layoutDependency={layoutDependency} layoutId={"DANqOaymC"} style={{"--extracted-r6o4lv": "var(--token-1b444440-6e98-401c-93cc-651f6c08f890, rgb(20, 20, 31))", "--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline"}} text={xjlQGu0Qd} verticalAlignment={"top"} withExternalLayout/></motion.div></Transition></Variants></LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-FoTjI.framer-6t5ow4, .framer-FoTjI .framer-6t5ow4 { display: block; }", ".framer-FoTjI.framer-ws0fe { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 0px; height: min-content; justify-content: center; overflow: hidden; padding: 8px 20px 8px 20px; position: relative; width: min-content; will-change: var(--framer-will-change-override, transform); }", ".framer-FoTjI .framer-1ljtkim { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-FoTjI.framer-ws0fe { gap: 0px; } .framer-FoTjI.framer-ws0fe > * { margin: 0px; margin-left: calc(0px / 2); margin-right: calc(0px / 2); } .framer-FoTjI.framer-ws0fe > :first-child { margin-left: 0px; } .framer-FoTjI.framer-ws0fe > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 40
 * @framerIntrinsicWidth 85.5
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]}}}
 * @framerVariables {"xjlQGu0Qd":"cursorName"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerbyMa14LxY: React.ComponentType<Props> = withCSS(Component, css, "framer-FoTjI") as typeof Component;
export default FramerbyMa14LxY;

FramerbyMa14LxY.displayName = "cursor-view_case";

FramerbyMa14LxY.defaultProps = {height: 40, width: 85.5};

addPropertyControls(FramerbyMa14LxY, {xjlQGu0Qd: {defaultValue: "view", displayTextArea: false, title: "Cursor name", type: ControlType.String}} as any)

addFonts(FramerbyMa14LxY, [{explicitInter: true, fonts: [{family: "ABC Ginto Normal Regular", source: "custom", url: "https://framerusercontent.com/assets/Zo2iDvx3OzYE7u7BjTe2l2dHYI8.woff2"}]}], {supportsExplicitInterCodegen: true})